<template>
    <BannerTemplate :bannerSource="bannerSource">
        <LoadingIcon :isLoading="isLoading" />
        <div class="px-2 mt-n7 mb-5 admin-panel">
            <v-container>
                <v-row no-gutters>
                    <v-col cols="12" class="d-flex">
                        <div class="text-h5 mb-2">{{ title }}</div>
                        <v-spacer></v-spacer>
                        <div class="year-container">
                            <RoadtripYearDropdown :year="selectedYear" @changed="onYearChanged($event)" />
                        </div>
                        <div class="quarter-container">
                            <RoadtripQuarterDropdown :quarter="selectedQuarter" @changed="onQuarterChanged($event)" />
                        </div>
                    </v-col>
                    
                </v-row> 
                 <v-row no-gutters>
                    <v-col> {{ intro }}</v-col>
                 </v-row>
                <v-row no-gutters>
                    <v-col>
                        <p class="headline mt-3 mb-2">Roadtrips</p>
                        <div class="mb-4">Klik op de roadtrip om deze te wijzigen. De slider geeft de publicatiestatus weer.</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" v-for="(roadtrip, index) in roadtrips" :key="index" class="py-0">
                        <RoadtripCard 
                            :index="index"
                            :roadtrip="roadtrip"
                            @onSwitchClicked="onPublishChangeConfirmMessage"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-4">
                        <v-btn color="primary" block @click="goToNewRoadtrip()">
                            Voeg nieuwe roadtrip toe
                        </v-btn>
                    </v-col>
                </v-row>
                <StatisticsSection v-if="!untestedAdmin" :selectedYear="selectedYear" :selectedQuarter="selectedQuarter" />
                <MessageActionDialog @yes="togglechanged()" />
            </v-container>
        </div>
    </BannerTemplate>
</template>

<script>
import LoadingIcon from '@/components/common/LoadingIcon';
import BannerTemplate from '@/components/common/BannerTemplate';
import RoadtripCard from '@/components/admin/RoadtripCard';
import StatisticsSection from '@/components/admin/StatisticsSection';
import RoadtripYearDropdown from '@/components/common/options/RoadtripYearDropdown';
import RoadtripQuarterDropdown from '@/components/common/options/RoadtripQuarterDropdown';
import DataMixin from "@/mixins/data.mixin";
import FormatMixin from "@/mixins/format.mixin";
import RouteMixin from "@/mixins/route.mixin";
import RoadtripMixin from "@/mixins/roadtrip.mixin";
import MessageActionDialog from "@/components/dialogs/MessageActionDialog";

import { mapState } from 'vuex'

export default {
    name: 'AdminDashboard',
    components: 
    {
        LoadingIcon,
        BannerTemplate,
        RoadtripCard,
        StatisticsSection,
        RoadtripYearDropdown,
        RoadtripQuarterDropdown,
        MessageActionDialog
    },
    mixins: [DataMixin, FormatMixin, RouteMixin, RoadtripMixin],
    data: () => ({
     
        bannerSource: require('@/assets/album/My Roadpursuit/my-roadpursuit-02.jpg'),
        intro: "In het adminpanel is alle relevante Roadpursuit-informatie te vinden. Alle weergegeven informatie is op basis van het geselecteerde kwartaal. Standaard is dit het huidige kwartaal.",
        selectedYear: "",
        selectedQuarter: 1,
        roadtrips: [],
        isLoading: false,
        published: true,
        isMounted: false,
        untestedAdmin: true,
        currentRoadtripCardIndex: -1
    }),
    beforeCreate(){
        this.$store.dispatch('menuHelper/getProductStatuses');
    },
    mounted(){
        this.selectedYear = `${this.getCurrentYear()}`;
        this.selectedQuarter = this.getCurrentQuarter();
        this.isMounted = true;
        this.getRoadtripsByTimeFrame();
    },
    computed:
    {
        title() {
            return this.firstname ? `Welkom ${this.firstname},` : 'Welkom';
        },
        ...mapState('accountModule', {
            firstname: state => state.personInfo.firstname
        }),
        ...mapState('authorizedModule', ['isAdmin', 'userId']),
    },
    watch: {
        userId: {
            immediate: true,
            handler() {
                this.getAccountInformation();
            }
        },
    },
    methods: 
    {
        onYearChanged(year)
        {
            this.selectedYear = year;
            this.getRoadtripsByTimeFrame();
        },
        onQuarterChanged(quarter)
        {
            this.selectedQuarter = quarter;
            this.getRoadtripsByTimeFrame();
        },
        getRoadtripsByTimeFrame()
        {
            if(this.isMounted == false || this.isMounted == undefined) return;

            this.isLoading = true;
            let request = {
                year: this.selectedYear,
                quarter: this.selectedQuarter
            }
           
            this.$store.dispatch('roadtripModule/getRoadtripStatisticsByTimeFrame', request)
            .then(array => {
                this.roadtrips = [];
                array.forEach(roadtrip => {
                    roadtrip.dateOnly = this.getDateOnly(roadtrip.date);
                    roadtrip.isPublished = roadtrip.productStatus == 2;
                    roadtrip.tagColor = this.getTagColor(roadtrip.tripType);
                    this.roadtrips.push(roadtrip);
                });

                this.isLoading = false;
            })
            .catch(() =>
            {
                this.isLoading = false;
            });
        },
        getAccountInformation()
        {
            if(!this.userId || this.userId == undefined) return;
            this.$store.dispatch('getAccountInformation', this.userId);
        },
        onChangePassword()
        {
            this.valid = this.$refs.form.validate()

            if(this.valid)
            {
                // TODO: api call to send forgot password link
                this.isPasswordChanged = true;
            }
        },
        onPublishChangeConfirmMessage(index)
        {
            let roadtrip = this.roadtrips[index];
            const message = roadtrip.isPublished
            ? `Weet u zeker dat u de roadtrip ${roadtrip.name} privé wilt maken?`
            : `Weet u zeker dat u de roadtrip ${roadtrip.name} wilt publiceeren?`;
            this.$store.commit('dialog/showActionDialog', message);

            this.currentRoadtripCardIndex = index;
        },
        togglechanged()
        {
            let roadtrip = this.roadtrips[this.currentRoadtripCardIndex];
            let newStatus = !roadtrip.isPublished;
            const statusId = newStatus ? this.getProductStatusId("Published") 
                            : this.getProductStatusId("Private");
            const request = {
                roadtripId: roadtrip.roadtripId,
                productStatus: statusId
            }
            this.isLoading = true;
            this.$store.dispatch('productModule/adjustProductStatus', request)
            .then(() => {
                this.isLoading = false;
                this.$set(this.roadtrips[this.currentRoadtripCardIndex], 'isPublished', newStatus);
            })
            .catch(() =>
            {
                this.isLoading = false;
            });
        }
    }
}
</script>
<style>
.custom-icon
{
    width: 23px;
    height: 18px;
    margin-right: 5px;
}

.year-container
{
    max-width: 65px;
    margin-right: 10px;
}

.quarter-container
{
    max-width: 50px;
}

.roadtrip-card.theme--dark.v-card,
.roadtrip-card .v-list.v-sheet.theme--dark
{
    background-color: #181717;
}

.roadtrip-card .icon-wrap .subheading
{
    font-size: larger;
}

.tag-wrap .red
{
    background-color: red;
}
</style>
