<template>
    <div>
        <v-row>
            <v-col>
                <p class="headline mb-0">Financiën</p>
            </v-col>
        </v-row>
        <StatisticInfoCards 
            :title1="`Orders:`"
            :value1="orderCount"
            :title2="`Omzet:`"
            :value2="revenue"
            :value2class="`title`"
            :title3="`Kosten:`"
            :value3="costs"
            :value3class="`title`"
            />
        <v-row>
            <v-col>
                <div class="pb-3">{{finance}}</div>
                <v-btn color="primary" block @click="goToFinances()">
                    Bekijk het complete overzicht
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <p class="headline mb-0">Accounts</p>
            </v-col>
        </v-row>
        <StatisticInfoCards 
            :title1="`Totaal:`"
            :value1="accountTotalRegistered"
            :title2="`Actief:`"
            :value2="accountTotalActive"
            :title3="`Nieuw:`"
            :value3="accountTotalNew"
            />
        <v-row>
            <v-col>
                <div class="pb-3">{{accounts}}</div>
                <v-btn color="primary" block @click="goToAccounts()">
                    Bekijk alle accounts
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <p class="headline mb-0">Voertuigen</p>
            </v-col>
        </v-row>
        <StatisticInfoCards 
            :title1="`Totaal:`"
            :value1="totalVehicles"
            :title2="`Top merk:`"
            :value2="topMake"
            :value2class="`title`"
            :title3="`Nieuw:`"
            :value3="newVehicles"
            />
        <v-row>
            <v-col>
                <div class="pb-3">{{voertuigen}}</div>
                <v-btn color="primary" block @click="goToCars()">
                    Bekijk alle voertuigen
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <p class="headline mb-0">Leverancieren</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="pb-3">{{leverancieren}}</div>
                <v-btn color="primary" block @click="goToSuppliers()">
                    Bekijk alle Leverancieren
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import RouteMixin from "@/mixins/route.mixin";
import FormatMixin from "@/mixins/format.mixin";
import StatisticInfoCards from "@/components/admin/StatisticInfoCards";
export default {
    name: 'StatisticsSection',
    components: 
    {
        StatisticInfoCards
    },
    props: 
    {
        selectedYear: String,
        selectedQuarter: Number
    },
    watch: {
        selectedYear: {
            immediate: true,
            handler() {
                this.getStatistics();
            }
        },
        selectedQuarter: {
            immediate: true,
            handler() {
                this.getStatistics();
            }
        },
    },
    mixins: [RouteMixin, FormatMixin],
    data () {
        return {
            finance: "Hierboven zijn de belangrijkste statistieken weergegeven. Bekijk alle financiële gegevens door op onderstaande knop te drukken.",
            accounts: "Hierboven is te zien hoeveel accounts er zijn geregistreerd en hoeveel daarvan in het huidige kwartaal actief waren. Ook is te zien hoeveel nieuwe accounts er in de huidige periode zijn geregistreerd. Bekijk alle accounts door op onderstaande knop te drukken.",
            voertuigen: "Hierboven is te zien hoeveel voertuigen er zijn geregistreerd en wat het merk is dat het vaakste voorkomt. Ook is te zien hoeveel nieuwe voertuigen er in de huidige periode zijn geregistreerd. Bekijk alle voertuigen door op onderstaande knop te drukken.",
            leverancieren: "Hierboven is te zien hoeveel leverancieren er zijn geregistreerd en wat het merk is dat het vaakste voorkomt. Ook is te zien hoeveel nieuwe leverancieren er in de huidige periode zijn geregistreerd. Bekijk alle leverancieren door op onderstaande knop te drukken.",
            accountTotalRegistered: '',
            accountTotalActive: '',
            accountTotalNew: '',
            totalVehicles: '',
            topMake: '',
            newVehicles: '',
            orderCount: '',
            revenue: '',
            costs: ''
        }
    },
    methods: {
        getStatistics()
        {
            if(this.selectedYear == "" || !this.selectedQuarter) return;

            let request = {
                year: this.selectedYear,
                quarter: this.selectedQuarter
            }

            this.getAccountStatisticsByTimeFrame(request);
            this.getVehicleStatisticsByTimeFrame(request);
            this.getFinancialStatisticsByTimeFrame(request);
        },
        getAccountStatisticsByTimeFrame(request)
        {
            this.$store.dispatch('accountModule/getAccountStatisticsByTimeFrame', request)
            .then(data => {
                this.accountTotalRegistered = `${data.totalRegistered}`;
                this.accountTotalActive = `${data.totalActive}`;
                this.accountTotalNew = `${data.totalNew}`;
            })
            .catch(() => {
                this.accountTotalRegistered = ``;
                this.accountTotalActive = ``;
                this.accountTotalNew = ``;
                this.$store.commit('dialog/showDialog', 'Something went wrong with retrieving account statistics.');
            })
        },
        getVehicleStatisticsByTimeFrame(request)
        {
                     
            this.$store.dispatch('vehicleModule/getVehicleStatisticsByTimeFrame', request)
            .then(data => {
                this.totalVehicles = `${data.totalVehicles}`;
                this.topMake = `${data.topMake}`;
                this.newVehicles = `${data.newVehicles}`;
            })
            .catch(() => {
                this.totalVehicles = ``;
                this.topMake = ``;
                this.newVehicles = ``;
                this.$store.commit('dialog/showDialog', 'Something went wrong with retrieving vehicle statistics.');
            })
        },
        getFinancialStatisticsByTimeFrame(request)
        {  
            this.$store.dispatch('expenseModule/getFinancialStatisticsByTimeFrame', request)
            .then(data => {
                this.orderCount = `${data.orderCount}`;
                this.revenue = `${this.getCurrency(data.revenue)}`;
                this.costs = `${this.getCurrency(data.costs)}`;
            })
            .catch(() => {
                this.orderCount = ``;
                this.revenue = ``;
                this.costs = ``;
                this.$store.commit('dialog/showDialog', 'Something went wrong with retrieving financial statistics.');
            })
        },
    }
}
</script>
