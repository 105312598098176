<template>
    <div class="px-2">
        <v-container>
            <v-row no-gutters>
                <v-col>
                    <p class="display-1 mb-2">Geregistreerde passagiers</p>
                    <div>{{intro}}</div>
                </v-col>
            </v-row>
            <v-row class="position-relative">
                <v-col cols="12 py-0">
                    <v-divider color="white"></v-divider>
                </v-col>
                <v-col cols="12 py-0 position-relative" v-for="(item, index) in copassengers" :key="index">
                    <v-card class="mx-auto passenger-items" color="#181717">
                        <ListItem 
                            :index="index"
                            :isActive="item.isActive" 
                            :subtitle="item.phoneNumber" 
                            :initials="initials(item)"
                            :title="fullname(item)"
                            :description="`Allergieën en voorkeuren: ${getFoodRestrictions(item.foodRestrictions)}`" 
                            @setActiveItem="item.isActive = true"
                            @showDialog="showDialog" />
                        <PassengerForm 
                            :index="index"
                            :firstname="item.firstname"
                            :lastname="item.lastName"
                            :phoneNumber="item.phoneNumber"
                            :dateOfBirth="item.dateOfBirth"
                            :foodRestrictions="item.foodRestrictions"
                            :personId="item.personId"
                            :isActive="item.isActive"
                            :isNew="item.isNew"
                            @create="addPerson"
                            @update="updatePerson"
                            @cancel="cancel" />
                    </v-card>
                </v-col>
                <v-fade-transition>
                    <v-overlay
                        absolute
                        color="#181717" v-if="isLoading">
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                    </v-overlay>
                </v-fade-transition>
                <v-expand-transition>
                    <v-col cols="12" v-show="!hasNewInput">
                        <v-btn color="primary" block class="mt-5 mb-0" @click="addNewPassenger()">
                            Voeg nieuwe passagier toe
                        </v-btn>
                    </v-col>
                </v-expand-transition>
            </v-row>
        </v-container>
        <v-dialog
            v-model="dialog"
            max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    Bevestig verwijderen
                </v-card-title>

                <v-card-text>
                    Weet u zeker dat u  "{{ fullname(selectedItem) }}" wilt verwijderen?
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="dialog = false">
                    Annuleren
                </v-btn>

                <v-btn
                    text
                    @click="deletePerson()">
                    Verwijderen
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DataMixin from "@/mixins/data.mixin";
import ListItem from "@/components/dashboard/ListItem";
import PassengerForm from "@/components/dashboard/PassengerForm";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import FormatMixin from "@/mixins/format.mixin";

import { mapState } from 'vuex'

export default {
    name: 'PassengerList',
    components: 
    {
        ListItem,
        PassengerForm
    },
    props: {
        accountId: String,
    },
    data: () => ({
        editIcon: require('@/assets/icons/edit.svg'),
        deleteIcon: require('@/assets/icons/delete.svg'),
        
        copassengers: [],
        dialog: false,
        selectedIndex: -1,
        selectedItem: null,
        isLoading: false,
    }),
    mixins: [DataMixin, MenuHelperMixin, FormatMixin],
    computed:
    {
        ...mapState('account', ['status']),
        ...mapState('authorizedModule', ['isAdmin']),
        hasNewInput()
        {
            return this.copassengers.find(x => x.isNew);
        },
        intro() {
            return this.isAdmin ? `Hieronder staan de passagiers die op het account van ${this.accountFirstname} staan geregistreerd.` : `Hieronder ziet u het overzicht van de door u geregistreerde passagiers. Wil er de volgende keer met iemand anders met u meerijden? Of wilt u een bestaande passagier wijzigen? U regelt het gemakkelijk hieronder.`;
        }
    },
    mounted()
    {
        this.getCoregisteredPersons();
    },
    methods: 
    {
        getCoregisteredPersons()
        {
            this.isLoading = true;
            this.copassengers = [];
            this.$store.dispatch('accountModule/getCoRegisteredPersonsFromAccount', this.accountId)
            .then(array => {

                array.forEach(element => {
                    element.isNew = false;
                    element.isActive = false;
                    // remove time information

                    if (element.dateOfBirth)
                    {
                        element.dateOfBirth = this.getDateOnly(element.dateOfBirth);
                    }
                    this.copassengers.push(element);
                });

                this.isLoading = false;
            })
            .catch(() => {
                this.isLoading = false;
            })
        },
        fullname(item)
        {
            if(item == null)
            {
                return "";
            }

            return `${item.firstname} ${item.lastName}`;
        },
        initials(item)
        {
            if(item == null)
            {
                return "";
            }

            const names = item.lastName.split(' ');
            return `${item.firstname.charAt(0)}${names[names.length - 1].charAt(0)}`;
        },
        showDialog(index)
        {
            this.selectedItem = this.copassengers[index];
            this.selectedIndex = index;
            this.dialog = true;
        },
        deletePerson()
        {
            if(!this.status.loggedIn) return;

            this.isLoading = true;
            this.dialog = false;
            this.$store.dispatch('accountModule/deletePerson', this.selectedItem.personId)
            .then(() => {
                //this.getCoregisteredPersons();
                this.$delete(this.copassengers, this.selectedIndex);
                this.selectedItem.isActive = false;
                this.isLoading = false;
            })
            .catch((error) => 
            {
                this.$store.commit('dialog/showDialog', error);
                this.isLoading = false;
            })
        },
        addPerson(index, firstname, lastname, dateOfBirth, phoneNumber, foodRestrictions)
        {
            if(!this.status.loggedIn) return;

            this.isLoading = true;
            // Add new coregistered person
            let request = [{
                accountId: this.accountId,
                firstname: firstname,
                lastName: lastname,
                dateOfBirth: dateOfBirth,
                phoneNumber: phoneNumber,
                foodRestrictions: foodRestrictions,
            }]

            this.$store.dispatch('accountModule/addCoregisteredPersons', request)
            .then((data) => {
                if(data.length > 0)
                {
                    this.updateListInfo(index, data[0], firstname, lastname, dateOfBirth, phoneNumber, foodRestrictions);
                }
                else
                {
                    this.$store.commit('dialog/showDialog', `Something went wrong with adding person '${firstname}'.`);
                }
                
                this.isLoading = false;
            })
            .catch(() => 
            {
                this.$store.commit('dialog/showDialog', `Something went wrong with adding person '${firstname}'.`);
                this.isLoading = false;
            });
        },
        updatePerson(index, firstname, lastname, dateOfBirth, phoneNumber, foodRestrictions, personId)
        {
            if(!this.status.loggedIn) return;
            this.isLoading = true;

            let request = {
                personInfoRequest: {
                    accountId: this.accountId,
                    firstname: firstname,
                    lastName: lastname,
                    dateOfBirth: dateOfBirth,
                    phoneNumber: phoneNumber,
                    id: personId
                },
                foodInfoRequest: {
                    personId: personId,
                    foodRestrictions: foodRestrictions
                }
            }

            // Update base info and food info of coregistered account
            this.$store.dispatch('accountModule/updatePersonInfo', request)
            .then(() => {
                this.updateListInfo(index, personId, firstname, lastname, dateOfBirth, phoneNumber, foodRestrictions);
                this.isLoading = false;
            })
            .catch(() => 
            {
                this.$store.commit('dialog/showDialog', `Something went wrong with updating person '${firstname}'.`);
                this.isLoading = false;
            });
        },
        updateListInfo(index, id, firstname, lastname, dateOfBirth, phoneNumber, foodRestrictions)
        {
            let passenger = this.copassengers[index];
            passenger.personId = id;
            passenger.firstname = firstname;
            passenger.lastName = lastname;
            passenger.phoneNumber = phoneNumber;
            passenger.dateOfBirth = dateOfBirth;
            passenger.foodRestrictions = foodRestrictions;
            passenger.isActive = false;
            passenger.isNew = false;
        },
        cancel(index)
        {
            let passenger = this.copassengers[index];
            if (passenger.isNew)
            {
                this.$delete(this.copassengers, index);
            }
            else
            {
                passenger.isActive = false;
            }
        },
        addNewPassenger()
        {
            if(!this.status.loggedIn) return;

            this.copassengers.push({
                firstname: "", 
                lastName: "", 
                dateOfBirth: null,
                phoneNumber: "",
                foodRestrictions: [],
                isActive: true,
                isNew: true,
                personId: ''
            });
        }
    }
}
</script>
<style>
.header
{
    display: inline-flex;
}

.edit-icon
{
    width: 12px;
    height: 11px;
}

.edit-container
{
    background: #0071e3;
    position: absolute;
    bottom: 6px;
    left: 40px;
    padding-left: 7px;
    padding-right: 6px;
    cursor: pointer;
}

.delete-icon 
{
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-right: 10px;
    cursor: pointer;
}

.initials-avatar
{
    background: white;
    color: #181717;
    font-size: 26px;
    cursor: pointer;
}

.passenger-items
{
    box-shadow: none !important;
}

</style>