<template>
    <div class="px-2">
        <v-container>
            <v-row no-gutters>
                <v-col>
                    <p class="display-1 mb-2">Geregistreerde voertuigen</p>
                    <div>{{intro}}</div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12 py-0">
                    <v-divider color="white"></v-divider>
                </v-col>
                <v-col cols="12 py-0" v-for="(item, index) in cars" :key="index">
                    <ListItem 
                        :index="index"
                        :isActive="item.isActive" 
                        :subtitle="setSubtitle(item)" 
                        :initials="getVehicleMake(item.make).charAt(0)"
                        :title="setCarTitle(item)"
                        :description="setDescription(item)"
                        @setActiveItem="item.isActive = true"
                        @showDialog="showDialog" />
                    <CarForm 
                        :index="index"
                        :licensePlate="item.licensePlate"
                        :vehicleType="item.vehicleType"
                        :make="item.make"
                        :model="item.model"
                        :year="item.year"
                        :engineCapacity="item.engineCapacity"
                        :cylinders="item.cylinders"
                        :power="item.power"
                        :fuelType="item.fuelType"
                        :id="item.id"
                        :isActive="item.isActive"
                        :isNew="item.isNew"
                        @create="addCar"
                        @update="updateCar"
                        @cancel="cancel" />
                </v-col>
                <v-expand-transition>
                    <v-col cols="12" v-show="!hasNewInput">
                        <v-btn color="primary" block class="mt-5 mb-0" @click="addNewCar()">
                            Voeg nieuwe voertuig toe
                        </v-btn>
                    </v-col>
                </v-expand-transition>
            </v-row>
        </v-container>
        <v-dialog
            v-model="dialog"
            max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    Bevestig verwijderen
                </v-card-title>

                <v-card-text>
                    Weet u zeker dat u "{{ setCarTitle(selectedItem) }}" wilt verwijderen?
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="dialog = false">
                    Annuleren
                </v-btn>

                <v-btn
                    text
                    @click="deleteCar()">
                    Verwijder
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ListItem from "@/components/dashboard/ListItem";
import CarForm from "@/components/dashboard/CarForm";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import RouteMixin from "@/mixins/route.mixin";
import FormatMixin from "@/mixins/format.mixin";
import { mapState } from 'vuex'

export default {
    name: 'CarList',
    components: 
    {
        ListItem,
        CarForm
    },
    props: {
        accountId: String,
    },
    data: () => ({
        editIcon: require('@/assets/icons/edit.svg'),
        deleteIcon: require('@/assets/icons/delete.svg'),
        cars: [],
       
        dialog: false,
        selectedIndex: -1,
        selectedItem: null,
        accountFirstname: 'Peter',
        isLoading: false,
       
    }),
    computed:
    {
        ...mapState('account', ['status']),
        ...mapState('authorizedModule', ['isAdmin']),
        hasNewInput()
        {
            return this.cars.find(x => x.isNew);
        },
        intro() {
            return this.isAdmin ? `Hieronder staan de voertuigen die op het account van ${this.accountFirstname} staan geregistreerd.` : `Bekijk hier het overzicht van uw voertuig(en). Na iedere roadtrip wordt het totaal aantal kilometers dat uw voertuig heeft meegereden geüpdatet. Heeft u een nieuwe auto gekocht? Voeg hem hieronder toe! U kunt uw voertuig ook weer verwijderen.`;
        }
    },
    mixins: [MenuHelperMixin, RouteMixin, FormatMixin],
    mounted()
    {
        this.getRegisteredVehicles();
    },
    methods: 
    {
        getRegisteredVehicles()
        {
            this.isLoading = true;
            this.cars = [];
            this.$store.dispatch('vehicleModule/getVehiclesByAccountId', this.accountId)
            .then(array => {

                array.forEach(element => {
                    element.isNew = false;
                    element.isActive = false;
                    this.cars.push(element);
                });

                this.isLoading = false;
            })
        },
        setCarTitle(item)
        {
            if(item == null || item == undefined)
            {
                return "";
            }

            return `${this.getVehicleMake(item.make)} ${item.model} (${item.year})`; 
        },
        setSubtitle(item)
        {
            if(item == null || item == undefined)
            {
                return "";
            }

            let electricInfo = this.isElectricCar(item.fuelType) ? "" : `${item.engineCapacity} | ${item.cylinders} cilinder | `;
            return `${electricInfo}${this.getFuelType(item.fuelType).replace('_', ' ')} | ${item.power} pk`; 
        },
        setDescription(item)
        {
            if(item == null)
            {
                return "";
            }
            
            let kenteken = item.licensePlate != undefined && item.licensePlate.trim() != "" ? `Kenteken: ${item.licensePlate} | ` : "";
            return `${kenteken}Meegereden: ${item.participatedDistance} km`; 
        },
        showDialog(index)
        {
            this.selectedItem = this.cars[index];
            this.selectedIndex = index;
            this.dialog = true;
        },
        deleteCar()
        {
            if(!this.status.loggedIn) return;

            this.isLoading = true;
            this.dialog = false;
            this.$store.dispatch('vehicleModule/deleteVehicle', this.selectedItem.id)
            .then(() => {
                this.$delete(this.cars, this.selectedIndex);
                this.selectedItem.isActive = false;
                this.isLoading = false;
            })
            .catch(error => 
            {
                this.$store.commit('dialog/showDialog', error);
                this.isLoading = false;
            })
            
        },
        addCar(index, request)
        {
            if(!this.status.loggedIn) return;

            request.participatedTrips = 0;
            request.participatedDistance = 0;
            request.accountId = this.accountId

            this.$store.dispatch('vehicleModule/addVehicle', request)
            .then(id => {
                request.id = id;
                this.updateListInfo(index, request);
                this.isLoading = false;
            })
            .catch(() => 
            {
                this.$store.commit('dialog/showDialog', `Something went wrong with registering vehicle.`);
                this.isLoading = false;
            });
        },
        updateCar(index, request)
        {
            if(!this.status.loggedIn) return;

            request.participatedTrips = 0;
            request.participatedDistance = 0;

            this.$store.dispatch('vehicleModule/updateVehicle', request)
            .then(() => {
                this.updateListInfo(index, request);
                this.isLoading = false;
            })
            .catch(() => 
            {
                this.$store.commit('dialog/showDialog', `Something went wrong with updating vehicle.`);
                this.isLoading = false;
            });
        },
        updateListInfo(index, params)
        {
            let car = this.cars[index];
            car.id = params.id;
            car.vehicleType = params.vehicleType;
            car.licensePlate = params.licensePlate;
            car.make = params.make;
            car.model = params.model;
            car.year = params.year;
            car.engineCapacity = params.engineCapacity;
            car.cylinders = params.cylinders;
            car.power = params.power;
            car.fuelType = params.fuelType;
            car.isActive = false;
            car.isNew = false;
        },
        cancel(index)
        {
            let car = this.cars[index];
            if (car.isNew)
            {
                this.$delete(this.cars, index);
            }
            else
            {
                car.isActive = false;
            }
        },
        addNewCar()
        {
            if(!this.status.loggedIn) return;
            
            this.cars.push({ 
                id: "",
                vehicleType: 0,
                make: null, 
                model: "",
                year: 0, 
                cylinders: 0,
                fuelType: 0,
                licensePlate: "",
                power: 0,
                engineCapacity: 0,
                participatedDistance: 0,
                isActive: true,
                isNew: true
            });
        },
        onTitleClick(index)
        {
            let car = this.cars[index];
            this.goToCar(car.id);
        }
    }
}
</script>
