<template>
    <div>
        <v-expand-transition>
            <div class="py-0">
                <v-divider color="white"></v-divider>
                <v-card class="roadtrip-card">
                    <v-card-title class="body-1 cols-12 pt-3 pb-0 pl-0 pr-8">
                        <span class="headline pointer" @click="onRoadtripClicked()">{{roadtrip.name}}</span>
                        <span class="headline mx-2 body-1 font-weight-light text-lowercase">{{formatDayMonth(roadtrip.dateOnly)}}</span>
                        <span class="body-1 tag-wrap">
                                <v-alert class="tag ma-0 pa-0 text-center red" 
                                    :class="`${roadtrip.tagColor}`"
                                    text--white
                                    width="100"
                                    dense
                                    prominent
                                    text>{{roadtrip.tripType}}</v-alert>
                        </span>
                        <span class="roadtrip-card-edit">
                            <img class="icon pointer" width="20" height="20" :src="editIcon" @click="onRoadtripEdit()">
                        </span>
                    </v-card-title>
                    <v-list class="pa-0">
                        <v-list-item>
                            <v-row justify="start">

                            <v-switch class="mt-5" color="green"
                            v-model="roadtrip.isPublished"
                            readonly
                            @click="onPublishChangeConfirmMessage()"
                            inset></v-switch>

                            <div class="my-auto mr-2 icon-wrap">
                                <img class="custom-icon" :src="carIcon" />
                                <span class="subheading mr-2">{{roadtrip.registerCount}} / {{roadtrip.maxRegistrations}}</span>
                            </div>

                            <div class="my-auto mr-2 icon-wrap">
                                <img class="custom-icon" :src="participantIcon" />
                                <span class="subheading mr-2">{{roadtrip.peopleCount}}</span>
                            </div>
                            
                            <div class="my-auto mr-2 icon-wrap">
                                <img class="custom-icon" :src="lunchIcon" />
                                <span class="subheading mr-2">{{roadtrip.lunchCount}}</span>
                            </div>
                            
                            <div class="my-auto mr-2 icon-wrap"><img class="custom-icon" :src="hotelIcon" />
                                <span class="subheading">{{roadtrip.hotelCount}}</span>
                            </div>
                            
                            </v-row>
                        </v-list-item>
                    </v-list>
                </v-card>
            </div>
        </v-expand-transition>
    </div>
</template>

<script>
import RouteMixin from "@/mixins/route.mixin";
import FormatMixin from "@/mixins/format.mixin";

export default {
    name: 'RoadtripCard',
    components: 
    {
    },
    mixins: [RouteMixin, FormatMixin],
    data () {
        return {
            carIcon: require('@/assets/icons/car-v2.svg'),
            participantIcon: require('@/assets/icons/participant.svg'),
            lunchIcon: require('@/assets/icons/lunch.svg'),
            hotelIcon: require('@/assets/icons/hotel.svg'),
            editIcon: require('@/assets/icons/edit.svg'),
        }
    },
    props: 
    {
        index: Number,
        roadtrip: Object,
    },
    methods: {
        onPublishChangeConfirmMessage()
        {
            this.$emit('onSwitchClicked', this.index);
        },
        onRoadtripClicked()
        {
            this.goToCalendar(this.roadtrip.name, this.formatDate(this.roadtrip.dateOnly));
           
            // TODO: Replace with this page when this is properly implemented 
            // #348 - To Roadtrip (Bestaand)
            //this.goToRoadtrip(this.roadtrip.roadtripId);
        },
        onRoadtripEdit()
        {
            this.goToRoadtripWijzigen(this.roadtrip.roadtripId);
        }
    },
}
</script>
<style scoped>
.roadtrip-card
{
    box-shadow: none !important;
}

.roadtrip-card-edit
{
    position: absolute;
    right: 5px;
    top: 20px;
}
</style>