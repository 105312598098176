<template>
     <v-expand-transition>
        <div v-show="!isActive" >
            <v-list-item class="px-0 pt-1">
                <v-list-item-avatar width="60" height="60">
                    <div class="pa-7 rounded-circle d-inline-block initials-avatar text-uppercase font-weight-black" @click="setActiveItem()">{{initials}}</div>
                </v-list-item-avatar>
                <div class="rounded-circle edit-container" @click="setActiveItem()">
                    <img class="edit-icon" :src="editIcon" alt="Edit" />
                </div>
                <v-list-item-content>
                    <v-list-item-title v-text="title" class="header-title pb-2 pointer" @click="onTitleClick()"></v-list-item-title>
                    <div> {{subtitle}} </div>
                </v-list-item-content>

                <img class="delete-icon" :src="deleteIcon" alt="Delete" @click="showDialog()" />

            </v-list-item>
            <div class="pb-3">
                {{description}} 
            </div>
            <v-divider color="white"></v-divider>
        </div>
    </v-expand-transition>
</template>

<script>
export default {
    name: 'ListItem',
    components: 
    {
    },
    data () {
        return {
            editIcon: require('@/assets/icons/edit.svg'),
            deleteIcon: require('@/assets/icons/delete.svg'),
        }
    },
    props: 
    {
        index: Number,
        isActive: Boolean,
        initials: String,
        title: String,
        subtitle: String,
        description: String,
    },
  
    methods: {
        setActiveItem()
        {
            this.$emit('setActiveItem');
        },
        onTitleClick()
        {
            this.$emit('onTitleClick', this.index);
        },
        showDialog()
        {
            this.$emit('showDialog', this.index);
        },
    },
}
</script>
