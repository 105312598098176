<template>
    <v-expand-transition>
        <div v-show="isActive">
            <v-row class="mt-0">
                <v-col>
                    <p class="headline mb-2">Persoonsgegevens</p>
                    <div>{{intro}}</div>
                </v-col>
            </v-row>
            <v-form ref="passengerForm">
                <v-text-field 
                    v-model="txt_firstname"
                    label="Voornaam*"
                    @keypress="isNonNumeric($event)"
                    :rules="[rules.required, rules.nameLength, rules.validName]"
                    required />
                <v-text-field 
                    v-model="txt_lastname"
                    label="Achternaam*"
                    @keypress="isNonNumeric($event)"
                    :rules="[rules.required, rules.nameLength, rules.validName]"
                    required />
                <v-text-field 
                    v-model="txt_phone"
                    @keypress="isPhoneNumberKey($event)"
                    :rules="[rules.phoneNumberLength]"
                    label="Telefoonnummer"  />
                <div class="mb-3">
                    <DatepickerFormatted :label="`Geboortedatum`" :isRequired="false" :date="txt_dateOfBirth" @changed="txt_dateOfBirth = $event" :showIcon="true" :isBirthdate="true" />
                </div>

                <FoodRestrictionDropdown :allergies="foodRestrictions" @changed="txt_allergies = $event" />
                <v-btn color="primary" class="mt-6" block @click="savePerson()">
                    Opslaan
                </v-btn>
            </v-form>
            <v-row class="justify-center text-center" no-gutters>
                <v-card-actions>
                    <v-container >
                        <v-row dense>
                            <v-col cols="12">
                                Geen persoon toevoegen?
                                <span class="link" @click="cancel()"> 
                                    Annuleer.
                                </span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-row>
        </div>
    </v-expand-transition>
</template>

<script>
import DataMixin from "@/mixins/data.mixin";
import GlobalMixin from "@/mixins/global.mixin";
import DatepickerFormatted from '@/components/common/DatepickerFormatted';
import FoodRestrictionDropdown from '@/components/common/options/FoodRestrictionDropdown';
export default {
    name: 'PassengerForm',
    components: 
    {
        DatepickerFormatted,
        FoodRestrictionDropdown
    },
    data () {
        return {
            showOptions: false,
            intro: "Pas hieronder de gegevens van uw passagier aan en druk op opslaan wanneer u klaar bent.",
            txt_firstname: "", 
            txt_lastname: "", 
            txt_phone: "",
            txt_dateOfBirth: null,
            txt_allergies: [],
            valid: true,
        }
    },
    props: 
    {
        index: Number,
        firstname: String,
        lastname: String,
        phoneNumber: String,
        dateOfBirth: String,
        foodRestrictions: Array,
        personId: String,
        isActive: Boolean,
        isNew: Boolean,
    },
    mixins: [DataMixin, GlobalMixin],
    watch: {
        isActive: 'setInitial'
    },
    methods: {
        setInitial()
        {
            if (!this.isActive)
            {
                return;
            }
            this.showOptions = false;
            this.txt_firstname = this.firstname;
            this.txt_dateOfBirth = this.birthdate;
            this.txt_lastname = this.lastname;
            this.txt_phone = this.phoneNumber;
            this.txt_dateOfBirth = this.dateOfBirth;
            this.txt_allergies = this.foodRestrictions;
        },
        savePerson()
        {
            const valid = this.$refs.passengerForm.validate()

            if (valid)
            {
                if(this.isNew)
                {
                    this.$emit('create', 
                        this.index,
                        this.txt_firstname,
                        this.txt_lastname,
                        this.txt_dateOfBirth,
                        this.txt_phone,
                        this.txt_allergies
                    );
                }
                else
                {
                    this.$emit('update', 
                        this.index,
                        this.txt_firstname,
                        this.txt_lastname,
                        this.txt_dateOfBirth,
                        this.txt_phone,
                        this.txt_allergies,
                        this.personId
                    );
                }
                
            }
        },
        cancel()
        {
            this.$emit('cancel', this.index);
        },
    },
}
</script>

<style>
.new-planning .v-expansion-panel-content__wrap
{
    padding: 0px;
    background: #181717;
}

.hide
{
    visibility: hidden !important;
    opacity: 0 !important;
    height: 0 !important;
    overflow: hidden;
    transition: height 0ms 400ms, opacity 400ms 0ms !important;
}

.new-planning .add-planning
{
    visibility: visible;
    opacity:1;
    height: auto;
    transition: height 0ms 0ms, opacity 600ms 0ms;
}
</style>