<template>
    <div>
       
        <div v-if="isAdmin">
            <AdminDashboard />
        </div>
        <div v-if="!isAdmin">
            <BannerTemplate :bannerSource="bannerSource" :title="title" :intro="intro">
                <LoadingIcon :isLoading="isLoading" />
                <div v-if="!isLoading" >
                     <div class="px-2">
                        <v-container class="py-0">
                            <v-row>
                                <v-col>
                                    <p class="display-1 mb-0">Aangemelde roadtrips</p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                    <div class="px-2">
                        <v-container>
                            <v-row v-if="upcomingRoadtrips.length == 0">
                                <v-col cols="12">
                                    Roadtrips waarvoor u zich heeft aangemeld worden hier weergegeven. Omdat u zich nog niet voor een roadtrip heeft aangemeld staat hier nog niks. Rijdt u graag een keer mee? Bekijk dan onze kalender om te zien wanneer we weer op pad gaan.
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" block @click="goToMainCalendar()">
                                        Naar de kalender
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="upcomingRoadtrips.length > 0">
                                <horizontal-scroll class="horizontal-scroll">
                                    <div class="outer">
                                        <template v-for="(event, index) in upcomingRoadtrips" >
                                            <div class="inner-content mt-2" :key="index">
                                                <v-container class="pr-0">
                                                    <ImageRedirect @imgClick="goToUpComingTrip(event.name, formatDateWithoutTime(event.date))" :imageSource="event.imageURL" />
                                                    <v-row>
                                                        <v-col>
                                                            <p class="details mt-1 mb-0 text-end" style="font-size: 22px">{{event.name}} ({{formatDayMonth(getDateOnly(event.date))}})</p>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </div>
                                        </template>
                                    </div>
                                </horizontal-scroll>
                            </v-row>
                        </v-container>
                    </div>
                    <div>
                        <div class="px-2">
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <p class="display-1 mb-2">Afgelopen roadtrips</p>
                                        <div>Via onderstaande knop kunt u alle roadtrips
                                        waaraan u heeft meegedaan bekijken. U vindt hier
                                        uw facturen, foto's en (geplaatste) reviews.</div>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn color="primary" block @click="goToAllPastTrips()">
                                            Bekijk afgelopen roadtrips
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                        <PassengerList :accountId="userId" />
                        <CarList :accountId="userId" />
                        <div class="px-2">
                            <v-container>
                                <v-row no-gutters>
                                    <v-col>
                                        <p class="display-1 mb-2">Accountgegevens</p>
                                        <div>Via onderstaande knop kunt u al uw
                                            accountgegevens beheren. Denk bijvoorbeeld aan
                                            uw wachtwoord, uw naam of uw adres. Als u
                                            allergieën of etensvoorkeuren heeft kunt u die hier
                                            ook aanpassen.
                                        </div>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn color="primary" block class="mt-5 mb-5" @click="goToAccount()">
                                            Beheer accountgegevens
                                        </v-btn>
                                    </v-col>
                                    
                                </v-row>
                            </v-container>
                        </div>
                    </div>
                </div>
            </BannerTemplate>
        </div>
        <div class="px-2 mb-3" v-if="!isLoading">
            <v-container  >
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-btn @click="logoutAndRevoke()" block>Logout</v-btn>
                    </v-col>
                </v-row>
            </v-container>  
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BannerTemplate from '@/components/common/BannerTemplate';
import CarList from "@/components/dashboard/CarList";
import PassengerList from "@/components/dashboard/PassengerList";
import DataMixin from "@/mixins/data.mixin";
import FormatMixin from "@/mixins/format.mixin";
import RouteMixin from "@/mixins/route.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";
import HorizontalScroll from 'vue-horizontal-scroll'
import ImageRedirect from '@/components/common/ImageRedirect';
import AdminDashboard from '@/components/admin/Dashboard';
import LoadingIcon from '@/components/common/LoadingIcon';
import TokenService from "@/services/token.service";

import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'

export default {
    name: 'Dashboard',
    components: 
    {
        CarList,
        ImageRedirect,
        PassengerList,
        BannerTemplate,
        HorizontalScroll,
        AdminDashboard,
        LoadingIcon
    },
    data: () => ({
        bannerSource:  require('@/assets/album/My Roadpursuit/my-roadpursuit-02.jpg'),
        intro: "Dit is uw Roadpursuit Dashboard. Vanaf hier kunt u alles regelen en bekijken, bijvoorbeeld de roadtrips waarvoor u zich heeft aangemeld, roadtrips die al hebben plaatsgevonden, maar ook uw passagiers, voertuigen en accountgegevens.",
        upcomingRoadtrips: [],
        isLoading: false
    }),
    computed: {
        ...mapState('account', ['status']),
        ...mapState('authorizedModule', ['isAdmin', 'userId']),
        ...mapState('accountModule', {
            firstname: state => state.personInfo.firstname
        }),
        title() {
            return this.firstname ? `Welkom ${this.firstname},` : 'Welkom';
        },
       
    },
    mixins: [DataMixin, FormatMixin, RouteMixin, MetaTagsMixin],
    watch: {
        userId: {
            immediate: true,
            handler() {
                this.getAccountInformation();
            }
        }
    },
    beforeCreate(){
        this.$store.dispatch('menuHelper/getFoodRestrictions');
        this.$store.dispatch('menuHelper/getVehicleOptions');
    },
    mounted() {
        this.$store.commit('setFooterVisibility', true);
    },
    metaInfo() {
        return {
        title: "| Mijn Roadpursuit",
        meta: [
            this.getKeywords(`Dashboard, Mijn Roadpursuit, inloggen`),
            this.getMetaDescription('Mijn Roadpursuit dashboard'),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`Mijn Roadpursuit`, `Mijn Roadpursuit dashboard`, `${window.location.origin}${this.bannerSource}`, 'Mijn Roadpursuit'),
        ]
        };
    },
    methods: 
    {
        ...mapActions('account', ['logout']),
        getAccountInformation()
        {
            if (this.isAdmin) return;
            
            this.isLoading = true;
            if(!this.userId || this.userId == undefined) return;
            this.$store.dispatch('getAccountInformation', this.userId)
            .then(() => {
                this.getUpcomingRoadtrips();

            })
            .catch(() => {
                this.isLoading = false;
            })
        },
        getUpcomingRoadtrips()
        {
            this.isLoading = true;
            this.upcomingRoadtrips = [];
            this.$store.dispatch('roadtripModule/getUpcomingRoadtrips', this.userId)
                .then(data => {
                    this.upcomingRoadtrips = data;
                    this.isLoading = false;
            })
            .catch(() => { this.isLoading = false; });
        },
        logoutAndRevoke()
        {
            // remove token first before removing user session
            this.$store.dispatch('accountModule/revokeToken', { token: TokenService.getLocalRefreshToken()});
            this.$store.commit('authorizedModule/setUserId', '');
            this.logout();
        },
    }
}
</script>
<style scoped>
.content
{
    padding: 10px;
}
.header {
    color: #f7f7f7;
    display: inline-flex;
}

.edit-container .edit-icon
{
    width: 12px;
    height: 12px;
    cursor: pointer;
}

.horizontal-scroll {
    display: flex;
    width: 100%;
}

.outer {
    display: flex;
    flex: 1;
    width: auto;
    height: 100%;
    flex-flow: row nowrap;
}

.inner-content {
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 280px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.inner-content:not(:first-of-type) {
    margin-left: 10px;
}

p.details
{
    overflow: hidden;
    overflow-wrap: break-word;
    word-break: unset;
    white-space: break-spaces;
    text-overflow: ellipsis;
}

</style>